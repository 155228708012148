/* eslint-disable no-mixed-spaces-and-tabs */ /* eslint-disable
no-mixed-spaces-and-tabs */
<template>
  <q-card
    class="column no-wrap task-timer"
    style="
      max-height: calc(100vh - 125px);
      width: 1080px;
      max-width: 1080px;
      border-radius: 15px;
    "
  >
    <!-- Sections of dialog -->
    <div
      v-if="subtaskLoading"
      class="column items-center justify-center"
      style="height: 500px"
    >
      <q-circular-progress
        indeterminate
        :thickness="0.1"
        size="75px"
        color="primary"
        class="q-ma-md"
      />
    </div>
    <q-card-section
      class="row col-shrink no-wrap q-pa-none task-dialog"
      ref="taskDialogScrollSection"
      v-else
    >
      <div style="width: 100%;">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="closeDialog"
          v-close-popup
          style="position: absolute; right: 5px; top: 5px;z-index: 1;background-color: #fff;"
        />
      </div>
      <!-- LEFT SECTION -->
      <div class="column col-grow task-dialog-left-section relative-position">
        <div class="flex-1 flex-y">
          <div style="width: 100%" class="row justify-between items-center">
            <div
              style="width: 10%"
              class="flex-1 task-ticket-number q-mr-xs"
              v-if="!hideTaskArrow"
            >
              <q-btn
                flat
                :icon="$icons.matArrowBack"
                class=""
                round
                color="dark"
                size="sm"
                @click="backToTask(tasks.id)"
              />
            </div>
            <div
              :class="[
                isInvalid && 'error-indicator',
                'flex task-title-wrapper q-mt-md q-ml-md q-mr-md row items-center',
              ]"
              style="width: 90%; border-radius: 5px; white-space: normal"
              @click="handleTitleEditorFocus"
            >
              <div
                v-if="!editorTitleFocused && taskData.title"
                style="padding: 6px 0px 6px 10px; margin-top: 0; width: 100%"
                class="dont-break-out q-mb-sm task-title bg-color rounded-borders"
                @click="handleTitleEditorFocus"
              >
                {{ taskData.title && taskData.title }}
              </div>
              <div style="width: 100%" v-else>
                <q-input
                  outline
                  borderless
                  input-class="transparent task-title"
                  placeholder="Task Name"
                  standout="bg-color rounded-borders"
                  class="col-grow q-pb-none"
                  v-model="taskData.title"
                  @input="updateSubTaskTitle"
                  @focus="handleTitleEditorFocus"
                  @keyup.enter="updateSubTaskTitle"
                  autofocus
                  input-style="padding-left: 10px"
                />
              </div>
            </div>
          </div>
          <!--  eslint-disable-next-line vue/valid-v-on -->
          <div id="scrollableTaskDialog" class="flex-1 overflow-auto">
            <div
              v-if="isVisible([1, 2, 3])"
              class="d-grid task-dialog-controls q-pl-lg"
              style="grid-template-columns: 172px 172px 172px 172px"
            >
              <div class="section q-mr-md">
                <div class="row items-center">
                  <div>
                    <img
                      class="display-icon"
                      src="../../../public/static/images/taskdialog/assignee.svg"
                    />
                  </div>
                  <div class="title q-py-sm q-mr-sm">Assignee</div>
                  <assign-member-to-subtask
                    v-if="isVisible([1, 2]) || subTask.owner.id === user.id"
                    :workspaceMembers="workspaceMemberList"
                    :currentWorkspaceId="workspace.id"
                    :subtask="subTask"
                    class="q-mr-xs"
                    @menuClosed="
                      () => {
                        isHover = false;
                      }
                    "
                    :fromNewTask="false"
                    :fromSubTask="true"
                    :currentCompany="currentCompany"
                  />
                </div>
                <div class="list">
                  <div
                    class="item row items-center flex-no-wrap justify-between"
                    v-for="(member, i) in taskMembers"
                    :key="'taskMember' + i"
                  >
                    <div class="row flex-no-wrap items-center">
                      <q-avatar size="sm" @click="showUserInfo(member)" class="user_pic">
                        <img
                          v-if="member.pic"
                          :src="member.pic"
                        />
                        <!-- <span else
                          >{{ member.first | firstChar
                          }}{{ member.last | firstChar }}</span
                        > -->
                        <avatar v-else :size="24" :customStyle="{'font-size':'12px', 'font-weight':'600'}" :username="member.first+' '+member.last"></avatar>
                      </q-avatar>
                      <div
                        style="margin-left: 10px"
                        @click="showUserInfo(member)"
                      >
                        {{ member.first + " " + member.last }}
                      </div>
                    </div>
                    <div
                      class="close"
                      @click="confirmBoxHandler(true, member.id)"
                      v-if="
                        isVisible([1, 2]) ||
                        isWorkspaceOwner ||
                        tasks.owner.id === user.id
                      "
                    >
                      <q-icon :name="$icons.matClose" size="xs" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="section">
                <div class="row items-center">
                  <div>
                    <img
                      class="display-icon"
                      src="../../../public/static/images/taskdialog/CalendarIcon.svg"
                    />
                  </div>
                  <div class="title q-py-sm q-mr-sm">Due date</div>
                  <q-btn
                    no-caps
                    size="sm"
                    align="left"
                    @click="datePickermenu = !datePickermenu"
                    :class="menu && 'active'"
                    class="task-control-button relative-position"
                  >
                    <div class="plus-icon">
                      <q-icon :name="$icons.fasPlus" size="12px" />
                    </div>
                  </q-btn>
                  <div>
                    <q-menu
                      v-model="datePickermenu"
                      max-height="auto"
                      anchor="top right"
                      self="top left"
                      content-class="overflow-auto"
                    >
                      <q-date
                        mask="YYYY-MM-DD"
                        v-model="date"
                        today-btn
                        class="date-picker-calendar"
                        @input="updatedDate"
                      >
                        <div
                          class="date-time-block text-center row items-center justify-center"
                          style="height: 35px"
                        >
                          <div class="date-label">
                            <span class="demi-bold"> Ends: </span>
                            <span class="an-14 demi-bold-text">
                              {{
                                taskData.end_date
                                  ? taskData.end_date.substring(0, 10)
                                  : date
                              }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="row items-center justify-between btn-section"
                        >
                          <q-btn
                            flat
                            class="btn-style clear"
                            @click="dateUpdateHandler(null)"
                            label="Clear"
                            no-caps
                          />
                          <q-btn
                            flat
                            color="black"
                            class="btn-style"
                            @click="datePickermenu = false"
                            label="Cancel"
                            no-caps
                          />
                          <q-btn
                            flat
                            padding="xs md"
                            class="bg-primary text-white btn-style"
                            style="width: 70px"
                            @click="dateUpdateHandler(date)"
                            label="OK"
                          />
                        </div>
                      </q-date>
                    </q-menu>
                  </div>
                </div>
                <div
                  v-if="taskData.end_date"
                  @click="datePickermenu = !datePickermenu"
                  :class="['task-due-date cursor-pointer', isEndDateDue]"
                  style="width: min-content; margin-top: 10px"
                  class="custom-task-tag relative-position"
                >
                  <div class="close" @click.stop="dateUpdateHandler(null)">
                    <q-icon :name="$icons.matClose" />
                  </div>
                  {{ taskData.end_date | dateMonthDateDefault }}
                  <q-tooltip> Due Date </q-tooltip>
                </div>
              </div>
              <div class="section">
                <div
                  class="row items-center cursor-pointer"
                  clickable
                  @click="$copyTextToClipboard(copySubTaskLink)"
                >
                  <q-btn
                    flat
                    :icon="$icons.matLink"
                    size="12px"
                    rounded
                    dense
                    padding="0"
                    style="color: #b8c1cb"
                    class="copy-task-link"
                  >
                  </q-btn>
                  <div class="copylink title q-py-sm q-ml-xs">
                    <span>Copy subtask link</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="q-px-lg q-mt-lg">
                <!--Description -->
                <div
                  v-if="!editorFocused"
                  class="q-mt-sm editor-prototype task-description-new-editor dont-break-out overflow-auto cursor-pointer task-description"
                  v-html="
                    descriptionData && descriptionData !== '<span></span>'
                      ? descriptionData
                      : '<p>Type task description...</p>'
                  "
                  @click="handleEditorFocus"
                ></div>
                <div
                  v-else
                  class="q-mt-sm editor-prototype task-description-new-editor"
                >
                  <editor
                    v-model="taskData.details"
                    :editor-style="{
                      'min-height': '58px',
                      background: '#FFFFFF',
                      height: 'auto',
                    }"
                    :minHeight="58"
                    @submit="updateTaskDescription"
                    @blur="updateTaskDescription"
                    @focus="handleEditorFocus"
                    :autofocus="true"
                    :maxHeight="125"
                    placeholder="Type task description..."
                    :showEditorMenu="false"
                    overflow="auto"
                  />
                </div>
              </div>
              <!-- <q-separator class="q-mt-md" style="background:#E3E8ED" /> -->
              <!-- Attachments Slot -->
              <div
                class="q-px-md attachment-wrapper relative-position"
                v-if="subTask && (subTask.media || taskFolders.length)"
              >
                <q-expansion-item
                  class="rounded-borders bg-white hide-expansion-hover"
                  header-class="q-px-none justify-between "
                  v-model="mediaExpansion"
                  @click="setSubtaskAttachmentState"
                >
                  <template v-slot:header>
                    <div class="q-py-sm flex-1">
                      <div class="row no-mrpd items-center">
                        <div class="no-mrpd attachment-title">
                          Attachments
                          {{ taskAttachments.length + taskFolders.length }}
                        </div>
                        <task-attachment-uploader
                          v-if="canPostMessageFromWorkspace"
                          v-show="mediaExpansion"
                          :mediaExpansion="mediaExpansion"
                          :authToken="authToken"
                          :isWorkspaceOwner="isWorkspaceOwner"
                          :currentWorkspace="workspace.id"
                          :imageList="imageList && imageList"
                          :currentUser="currentUser"
                          :currentCompany="currentCompany"
                          :task="subTask"
                          :updateTask="updateTask"
                          :attachTaskMediaHandler="attachTaskMediaHandler"
                          @updateMediaCache="
                            (data) => $emit('updateMediaCache', data)
                          "
                          @clickonmoreoption="
                            (data) => $emit('clickonmoreoption', data)
                          "
                          @deleteCommentPoint="
                            (data) => $emit('deleteCommentPoint', data)
                          "
                          @updatepositions="
                            (data) => $emit('updatepositions', data)
                          "
                          @updateMediaCommentPointLabel="
                            (data) =>
                              $emit('updateMediaCommentPointLabel', data)
                          "
                          @updateComment="
                            (data) => $emit('updateComment', data)
                          "
                          @sendComment="(data) => $emit('sendComment', data)"
                          @deleteMediaComment="
                            (data) => $emit('deleteMediaComment', data)
                          "
                          @closeMediaModel="
                            (data) => $emit('closeMediaModel', data)
                          "
                          @updateMediaCacheWithReactivity="
                            (data) =>
                              $emit('updateMediaCacheWithReactivity', data)
                          "
                        />
                      </div>
                    </div>
                  </template>
                  <q-list class="q-pb-xs">
                    <div
                      style="max-height: 258px"
                      class="overflow-auto q-pt-sm"
                    >
                      <template v-for="folder in taskFolders">
                        <task-folder-attachments
                          v-if="folder"
                          :key="folder.id"
                          :folder="folder"
                          :task="tasks"
                          :openFolder="openFolder"
                          :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                          :isWorkspaceOwner="isWorkspaceOwner"
                          @renameFolder="openRenameFolderDialog"
                        />
                      </template>
                      <div
                        v-sortable="{
                          onStart: onAttachmentDragStart,
                          onEnd: onAttachmentDragEnd,
                          onAdd: onAttachmentDragAddOrUpdate,
                          onUpdate: onAttachmentDragAddOrUpdate,
                          ghostClass: 'sortable-ghost',
                          emptyInsertThreshold: 100,
                        }"
                      >
                        <template v-for="attachment in taskAttachments">
                          <div :key="attachment.id" :data-id="attachment.id">
                            <task-attachment
                              size="35px"
                              :task="subTask"
                              :media="attachment"
                              :fullview="fullview"
                              :pdfView="pdfView"
                              :videoPlayer="videoPlayer"
                              :audioPlayer="audioPlayer"
                              :updateCoverImage="updateCoverImage"
                              :canRemoveMedia="canRemoveAttachment"
                              :detachTaskAttachmentHandler="
                                detachTaskAttachmentHandler
                              "
                              :editDoc="editDoc"
                              :fromComputer="() => fromComputer(attachment.id)"
                              :addMediaBookmark="addMediaBookmark"
                              :removeMediaBookmark="removeMediaBookmark"
                              :isWorkspaceOwner="isWorkspaceOwner"
                              @renameFile="openRenameFileDialog"
                              :currentUser="currentUser"
                            ></task-attachment>
                          </div>
                        </template>
                      </div>
                    </div>
                  </q-list>
                </q-expansion-item>
                <task-attachment-uploader
                  v-if="canPostMessageFromWorkspace"
                  :authToken="authToken"
                  :isWorkspaceOwner="isWorkspaceOwner"
                  :currentWorkspace="workspace.id"
                  :imageList="imageList && imageList"
                  :currentUser="currentUser"
                  :currentCompany="currentCompany"
                  :task="subTask"
                  :attachTaskMediaHandler="attachTaskMediaHandler"
                  :updateTask="updateTask"
                  :expandAttachmentHandler="() => (mediaExpansion = true)"
                  @updateMediaCache="(data) => $emit('updateMediaCache', data)"
                  @clickonmoreoption="
                    (data) => $emit('clickonmoreoption', data)
                  "
                  @deleteCommentPoint="
                    (data) => $emit('deleteCommentPoint', data)
                  "
                  @updatepositions="(data) => $emit('updatepositions', data)"
                  @updateMediaCommentPointLabel="
                    (data) => $emit('updateMediaCommentPointLabel', data)
                  "
                  @updateComment="(data) => $emit('updateComment', data)"
                  @sendComment="(data) => $emit('sendComment', data)"
                  @deleteMediaComment="
                    (data) => $emit('deleteMediaComment', data)
                  "
                  @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                  @updateMediaCacheWithReactivity="
                    (data) => $emit('updateMediaCacheWithReactivity', data)
                  "
                />
              </div>
              <q-separator style="background: #e3e8ed" />
              <!-- Subtasks Section -->
              <div
                class="subtasks-section q-pt-md q-pb-sm"
                v-if="subTask && subTask.childSubtasks"
              >
                <div
                  class="row items-center q-px-md justify-between stick-at-top"
                >
                  <div class="text-center row justify-center items-center">
                    <div class="title">
                      Sub-tasks: {{ completedCount }}/{{
                        subTask.childSubtasks.length
                      }}
                    </div>
                    <q-btn
                      dense
                      no-caps
                      flat
                      size="md"
                      class="control-button q-px-sm q-ml-md"
                      @click="visibleSubtaskAdder"
                      :style="{
                        height: '29px',
                        width: '86px',
                      }"
                      v-if="canPostMessageFromWorkspace"
                    >
                      <div style="display: flex; width: 100%" class="q-pl-sm">
                        <span>Add</span>
                      </div>
                      <q-tooltip>Create subtask</q-tooltip>
                    </q-btn>
                  </div>
                  <div v-if="subTask.childSubtasks.length">
                    <q-btn
                      dense
                      no-caps
                      flat
                      size="14px"
                      :style="
                        visibility === 'active'
                          ? {
                              'background-color': '#007aff',
                              color: 'white',
                            }
                          : {
                              'background-color': '#fff',
                              color: 'rgba(0,0,0,0.87)',
                            }
                      "
                      class="q-px-md q-ml-none"
                      @click="visibility = 'active'"
                      :label="`${activeCount} Active`"
                    />
                    <q-btn
                      dense
                      no-caps
                      flat
                      v-if="completedCount > 0"
                      size="14px"
                      :style="
                        visibility === 'completed'
                          ? {
                              'background-color': '#007aff',
                              color: 'white',
                            }
                          : {
                              'background-color': '#fff',
                              color: 'rgba(0,0,0,0.87)',
                            }
                      "
                      class="q-px-md q-ml-sm"
                      @click="visibility = 'completed'"
                      :label="`${completedCount} Completed`"
                    />
                    <q-btn
                      dense
                      flat
                      round
                      color="grey-10"
                      :icon="$icons.matMoreHoriz"
                      size="md"
                      @click.stop
                      v-if="
                        isVisible([1, 2]) ||
                        task.owner.id === user.id ||
                        isWorkspaceOwner
                      "
                    >
                      <q-tooltip>More</q-tooltip>
                      <q-menu auto-close>
                        <q-list>
                          <q-item
                            @click="deleteAllSubtasks(false)"
                            clickable
                            class="text-red"
                          >
                            <q-item-section>
                              Clear all active subtasks
                            </q-item-section>
                          </q-item>
                          <q-item
                            @click="deleteAllSubtasks(true)"
                            clickable
                            class="text-red"
                          >
                            <q-item-section>
                              Clear all completed subtasks
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                  </div>
                </div>
                <div class="q-mt-sm">
                  <!-- <div class="drag-scroll mb-2" v-drag-scroll v-if="showSubtasks"> -->
                  <div
                    class="subtask-main-div"
                    v-sortable="{
                      group: 'subtask',
                      handle: '.handle',
                      onAdd: onDragAddOrUpdate,
                      onUpdate: onDragAddOrUpdate,
                      emptyInsertThreshold: 1,
                    }"
                  >
                    <subtask
                      v-for="subtask in subTasks"
                      :key="subtask.id"
                      :ref="`subtask-${subtask.id}`"
                      :data-id="subtask.id"
                      :subtask="subtask"
                      :showUserInfo="showUserInfo"
                      :addSubtaskBelow="addSubtaskBelow"
                      :workspaceMembers="workspaceMemberList"
                      @updateSubtaskMod="updateSubtaskModHandler"
                      @statusUpdate="changeSubtaskCompleteStatus"
                      @update="updateSubtask"
                      :labels="taskLabels"
                      :task="tasks"
                      :folderList="folderList"
                      :currentWorkspaceId="workspace.id"
                      :currentWorkspace="workspace"
                      :currentUser="currentUser"
                      :canRemoveAttachment="canRemoveAttachment"
                      :removeMedia="removeMedia"
                      :deleteSubTaskHandler="deleteSubtask"
                      :editDoc="editDoc"
                      :openDocEditor="openDocEditor"
                      @closeMediaModel="
                        (data) => $emit('closeMediaModel', data)
                      "
                      @clickonmoreoption="
                        (data) => $emit('clickonmoreoption', data)
                      "
                      @deleteCommentPoint="
                        (data) => $emit('deleteCommentPoint', data)
                      "
                      @sendComment="(data) => $emit('sendComment', data)"
                      @updatepositions="
                        (data) => $emit('updatepositions', data)
                      "
                      @updateMediaCommentPointLabel="
                        (data) => $emit('updateMediaCommentPointLabel', data)
                      "
                      @deleteMediaComment="
                        (data) => $emit('deleteMediaComment', data)
                      "
                      @updateComment="(data) => $emit('updateComment', data)"
                      @showUserInfo="showUserInfo"
                      :currentSubtask="subTask"
                      :isFromSubtask="true"
                      :subTaskId="subTaskId"
                    ></subtask>
                  </div>
                  <!-- </div> -->
                  <!-- <div class="text-center" v-if="subtaskLoader">
                    <q-circular-progress
                      indeterminate
                      size="30px"
                      :thickness="0.2"
                      color="primary"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="[newSubtaskEditorVisibility && 'q-py-sm q-px-md']">
          <subtask-editor
            ref="subtaskEditor"
            v-show="newSubtaskEditorVisibility"
            :subtask="editSubtaskContent ? editSubtaskContent : null"
            :resetSubtaskEditor="resetSubtaskEditor"
            :openSubtask="openSubtask"
            :taggUsers="taggUsers"
            :isWorkspaceOwner="isWorkspaceOwner"
            :workspace="workspace"
            :currentUser="currentUser"
            :imageList="imageList && imageList"
            :newSubtaskEditorVisibility="newSubtaskEditorVisibility"
            :openFolder="openFolder"
            @visibleSubtaskAdder="visibleSubtaskAdder"
            :editDoc="editDoc"
            @addSubtask="addSubtask"
            @updateMediaCache="(data) => $emit('updateMediaCache', data)"
            @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
            @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
            @updatepositions="(data) => $emit('updatepositions', data)"
            @updateMediaCommentPointLabel="
              (data) => $emit('updateMediaCommentPointLabel', data)
            "
            @updateComment="(data) => $emit('updateComment', data)"
            @sendComment="(data) => $emit('sendComment', data)"
            @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
            @closeMediaModel="(data) => $emit('closeMediaModel', data)"
            @updateMediaCacheWithReactivity="
              (data) => $emit('updateMediaCacheWithReactivity', data)
            "
          />
        </div>
      </div>
      <!-- RIGHT SECTION -->
      <div
        :class="activeTab == 'comments' && 'justify-between'"
        class="column q-pb-md flex-no-wrap comment-section"
      >
        <!-- Comments -->
        <div class="q-px-md" style="position: relative">
          <q-toolbar class="q-pa-none flex" style="justify-content: center">
            <div class="custome-tab activity-tab activity-tab-task row q-mt-md">
              <q-tabs
                :value="activeTab"
                @input="changeActiveTab"
                style="width: 257px"
                class="text-dark"
                active-bg-color="dark"
                active-color="white"
                no-caps
              >
                <q-tab
                  class="relative-position"
                  name="comments"
                  style="width: 25px; border-radius: 15px"
                  inline-label
                  label="Comments"
                >
                  <!-- <q-tooltip>
                About this workspace
              </q-tooltip> -->
                </q-tab>
                <q-tab
                  class="relative-position"
                  name="notes"
                  style="width: 25px; border-radius: 15px"
                  inline-label
                  label="Notes"
                >
                </q-tab>
              </q-tabs>
            </div>
            <!-- <q-toolbar-title style="font-size: 18px; font-weight: 600">
              Comments
              <span v-if="totalCommentsCount">{{ totalCommentsCount }}</span>
            </q-toolbar-title> -->
            <!-- <q-btn
              :icon="$icons.matClose"
              text-color="blue-grey-8"
              round
              flat
              padding="0"
              dense
              @click="closeDialog"
              v-close-popup
            /> -->
          </q-toolbar>
        </div>
        <div
          class="scroll-y row justify-center items-center"
          v-if="!hasComments && activeTab == 'comments'"
          style="display: flex;flex: 1;margin-top: 24px;align-items: flex-end;"
        >
          <div class="text-center" style="width: 150px; white-space: pre-wrap">
            <q-avatar :style="{ background: '#dbecff' }" size="42px">
              <q-icon style="color: #007aff" :name="$icons.matComment" />
            </q-avatar>
            <div class="text-blue-grey-7 an-15 regular-text q-mt-md">
              There are no comments shared
            </div>
          </div>
        </div>
        <q-tab-panels :value="activeTab" :style="!hasComments && { 'display': 'flex', 'flex': 1 }">
          <q-tab-panel class="q-pa-none" name="comments" :style="!hasComments && {'display': 'flex'}">
            <div
              class="column flex-no-wrap"
              :style="!hasComments ? {'max-height': 'calc(98vh - 184px)', 'display': 'flex', 'flex': 1, 'justify-content': 'space-between'} : {'max-height': 'calc(98vh - 184px)'}"
            >
              <q-infinite-scroll
                id="commentScroll"
                class="overflow-auto"
                @load="fetchCommentScrollHandler"
                reverse
                :offset="10"
                :style="!hasComments && {'display': 'flex', 'flex': 1, 'align-items': 'flex-end'}"
              >
                <div class="q-mb-sm flex-1" v-if="hasComments">
                  <div
                    class="task-comments hide-expansion-arrow transparent elevation-0 post-expansion"
                  >
                    <div class="text-center" v-if="commentLoader">
                      <q-circular-progress
                        indeterminate
                        size="30px"
                        :thickness="0.2"
                        color="primary"
                      />
                    </div>
                    <q-expansion-item
                      v-for="(group, i) in commentGroup"
                      :key="i"
                      default-opened
                      class="q-mb-sm"
                      exact-active-class="transparent"
                      header-class="text-subtitle1 text-weight-bold text-grey-10 justify-center items-center text-uppercase	bg-white"
                      header-style="position:sticky;top:0;z-index: 100;min-height:24px"
                    >
                      <div class="flex-1">
                        <template v-for="(comment, index) in group">
                          <task-comment
                            :key="index"
                            :className="[
                              index === group.length - 1 ? '' : 'q-mb-md',
                            ]"
                            :comment="comment"
                            :reactions="reactions"
                            :task="tasks"
                            :isWorkspaceOwner="isWorkspaceOwner"
                            :currentUser="currentUser"
                            :taggUsers="taggUsers"
                            :currentWorkspace="workspace.id"
                            :labels="taskLabels"
                            :canRemoveAttachment="canRemoveAttachment"
                            :removeMedia="removeMedia"
                            :deleteTaskCommentHandler="deleteTaskCommentHandler"
                            :currentCompany="currentCompany"
                            :folderList="folderList"
                            :editDoc="editDoc"
                            :openDocEditor="openDocEditor"
                            @updateCommentMod="updateCommentModHandler"
                            @closeMediaModel="
                              (data) => $emit('closeMediaModel', data)
                            "
                            @clickonmoreoption="
                              (data) => $emit('clickonmoreoption', data)
                            "
                            @deleteCommentPoint="
                              (data) => $emit('deleteCommentPoint', data)
                            "
                            @sendComment="(data) => $emit('sendComment', data)"
                            @updatepositions="
                              (data) => $emit('updatepositions', data)
                            "
                            @updateMediaCommentPointLabel="
                              (data) =>
                                $emit('updateMediaCommentPointLabel', data)
                            "
                            @deleteMediaComment="
                              (data) => $emit('deleteMediaComment', data)
                            "
                            @updateComment="
                              (data) => $emit('updateComment', data)
                            "
                            @showUserInfo="showUserInfo"
                            @reactOnPost="(data) => $emit('reactOnPost', data)"
                            @replyTocomment="replyTocomment"
                          ></task-comment>
                        </template>
                      </div>
                      <template v-slot:header>
                        <div
                          :class="[
                            i === todaytask ? 'today-task' : '',
                            i === yesterdayTask ? 'yesterday-task' : '',
                          ]"
                          class="q-ml-lg"
                        >
                          {{ i | dateConversion }}
                        </div>
                      </template>
                    </q-expansion-item>
                  </div>
                </div>
              </q-infinite-scroll>
              <div class="position-relative q-ml-sm" style="margin: 0px auto">
                <comment-editor
                  v-model="taskData.details"
                  ref="commentEditor"
                  :openTaskComment="openTaskComment"
                  :forwardComment="repliedComment"
                  :mentions="taggUsers"
                  @submit="commentEditorActionsHandler"
                  @paste="handlePaste"
                  :taggUsers="taggUsers"
                  :isWorkspaceOwner="isWorkspaceOwner"
                  :workspace="workspace"
                  :taskLabels="taskLabels"
                  :imageList="imageList && imageList"
                  :currentUser="currentUser"
                  :task="tasks"
                  :editDoc="editDoc"
                  :openFolder="openFolder"
                  @clickonmoreoption="
                    (data) => $emit('clickonmoreoption', data)
                  "
                  @deleteCommentPoint="
                    (data) => $emit('deleteCommentPoint', data)
                  "
                  @updatepositions="(data) => $emit('updatepositions', data)"
                  @updateMediaCommentPointLabel="
                    (data) => $emit('updateMediaCommentPointLabel', data)
                  "
                  @updateComment="(data) => $emit('updateComment', data)"
                  @sendComment="(data) => $emit('sendComment', data)"
                  @deleteMediaComment="
                    (data) => $emit('deleteMediaComment', data)
                  "
                  @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                />
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel class="q-pa-none" name="notes">
            <div style="height: 100%;display: flex;overflow: hidden;">
              <task-document-editor-dialog
                :closeDocEditor="closeTaskNoteEditor"
                :documentProp="taskNotes"
                :tasks="tasks"
              />
              <!-- <DocumentEditor
                    :doc="taskNotes"
                    title="test"
                    :updateDocStateOnClose="updateDocStateOnClose"
                    @updateTitle="updateTitle"
                    :isMobileView="false"
                    class="document-editor overflow-hidden"
                  /> -->
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </q-card-section>
    <confirm-dialog
      v-if="confirmRemoveMember.flag"
      v-model="confirmRemoveMember.flag"
      title="Remove Member?"
      question="Are you sure you want to remove this member?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmBoxHandler(false)"
      :successMethod="deleteSubTaskMember"
      :loading="false"
    />
    <image-viewer-dialog
      :labels="taskLabels"
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="taskAttachments"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :resetDialog="() => resetDialog()"
      @clickonhidebutton="ishide = !ishide"
      @setActiveMedia="setActiveMedia"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
      @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
      @sendComment="(data) => $emit('sendComment', data)"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
      @updateComment="(data) => $emit('updateComment', data)"
    ></image-viewer-dialog>
    <pdf-view-dialog
      v-if="showpdfModel"
      v-model="showpdfModel"
      :fileExt="fileExt"
      @resetPdfDialog="showpdfModel = false"
      :pdfMediaData="pdfMediaData"
    />
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="currentUser"
      :currentWorkspace="workspace.id"
      :currentCompany="currentCompany"
      @close="clearUserInfoDialog"
    ></profile-dialog>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="taskAttachments"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    ></video-dialog>
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    />
    <div v-if="delete_media.flag">
      <confirm-dialog
        v-model="delete_media.flag"
        title="Delete File ?"
        question="Are you sure you want to detach the media?"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="() => removeMediaDialogHandler(false)"
        :successMethod="() => removeMediaDialogHandler(true)"
        :loading="false"
      />
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="workspace.id"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      @close="fileUploadCloseHandler"
      @setProgress="setProgress"
      :media_id="mediaId"
    ></vue-dropzone-file-upload>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      persistent
      v-model="editCommentDialog.flag"
      v-if="editCommentDialog.flag"
      content-class="edit-message-dialog"
    >
      <edit-comment-dialog
        :editCommentContent="editCommentContent"
        :mentionsList="taggUsers"
        :workspace="workspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :imageList="imageList"
        :currentUser="currentUser"
        :openFolder="openFolder"
        :editDoc="editDoc"
        @submit="editCommentHandler"
        @closeDialog="editCommentDialog.flag = false"
        @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
        @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
        @updatepositions="(data) => $emit('updatepositions', data)"
        @updateMediaCommentPointLabel="
          (data) => $emit('updateMediaCommentPointLabel', data)
        "
        @updateComment="(data) => $emit('updateComment', data)"
        @sendComment="(data) => $emit('sendComment', data)"
        @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
        @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      />
    </q-dialog>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      persistent
      v-model="replyCommentDialog.flag"
      v-if="replyCommentDialog.flag"
      content-class="reply-message-dialog"
    >
      <reply-comment-dialog
        :repliedComment="repliedComment"
        :mentionsList="taggUsers"
        :workspace="workspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :imageList="imageList"
        :currentUser="currentUser"
        :openFolder="openFolder"
        :editDoc="editDoc"
        @submit="replyCommentHandler"
        @showUserInfo="showUserInfo"
        @closeDialog="replyCommentDialog.flag = false"
        @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
        @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
        @updatepositions="(data) => $emit('updatepositions', data)"
        @updateMediaCommentPointLabel="
          (data) => $emit('updateMediaCommentPointLabel', data)
        "
        @updateComment="(data) => $emit('updateComment', data)"
        @sendComment="(data) => $emit('sendComment', data)"
        @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
        @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      />
    </q-dialog>
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="workspace.id"
      :user="currentUser"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :folderList="folderList"
      :authToken="authToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="copyFileFolderHandler"
      :currentWorkspaceId="workspace.id"
    />
    <confirm-dialog
      v-if="confirmRemoveField"
      v-model="confirmRemoveField"
      title="Remove Field?"
      question="Are you sure you want to remove this field?"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmRemoveFieldHandler(false)"
      :successMethod="handleDelete"
      :loading="false"
    />
    <confirm-dialog
      v-if="subtaskDeleteConfirmDailog.flag"
      v-model="subtaskDeleteConfirmDailog.flag"
      title="Remove subtasks?"
      :question="subtaskDeleteConfirmDailog.title"
      cancleText="Cancel"
      successText="Remove"
      :cancleMethod="() => confirmRemoveSubtaskHandler()"
      :successMethod="handleDeleteSubtasks"
      :loading="false"
    />
    <update-file-dialog
      v-if="renameFileDialog.flag"
      v-model="renameFileDialog.flag"
      :renameFile="renameFileDetails"
      :cancelMethod="cancel"
    />
    <create-media-folder-dialog
      v-if="renameMediaFolderDialog.flag"
      v-model="renameMediaFolderDialog.flag"
      :renameFolder="renameFolderDetails"
      :loading="renameMediaFolderDialog.loading"
      :createFolderHandler="renameFolderHandler"
      :cancelMethod="cancelFolder"
    />
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :openDocEditor="openDocEditor"
      :closeDocEditor="closeDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
    />
  </q-card>
</template>
<script>
const soundFile = require("@/assets/completeTaskSound.wav");
import RandomPosition from "random-position";
import { parseISO } from "date-fns";
import { isPast } from "date-fns";
import last from "lodash/last";
import head from "lodash/head";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import findLastIndex from "lodash/findLastIndex";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";
import UserScope from "@/mixins/UserScope";
//import TaskDialogInviteTaskMember from "@/components/TaskDialogInviteTaskMember";
import AssignMemberToSubtask from "@/views/BoardView/AssignMemberToSubtask";
import TaskComment from "./TaskComment";
import TaskAttachment from "./TaskAttachment";
import Subtask from "./Subtask";
import SubtaskEditor from "@/components/Editor/SubtaskEditor";
import Editor from "@/components/Editor/Editor";
import CommentEditor from "@/components/Editor/CommentEditor";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import ProfileDialog from "@/components/ProfileDialog";
import { compile } from "@/components/Editor/MarkupUtils";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import TaskAttachmentUploader from "./TaskAttachmentUploader";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import EditCommentDialog from "@/views/BoardView/EditCommentDialog";
import ReplyCommentDialog from "@/views/BoardView/ReplyCommentDialog";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";
import TaskDocumentEditorDialog from "@/components/Dialogs/TaskDocumentEditorDialog";
// import SubTaskDialog from "./SubTaskDialog";
import Avatar from 'vue-avatar'

import {
  NewMediaQuery,
  TaskQuery,
  SubtaskQuery,
  DetachSubtaskMediaMutation,
  DetachTaskCommentMediaMutation,
  DeleteSubTaskMemberMutation,
  AttachTaskLabelMutation,
  DetachTaskLabelMutation,
  UpdateTaskMutation,
  UpdateSubtaskMutation,
  UpdateTaskCommentMutation,
  UpdateNotificationsMutation,
  UpdateMediaMutation,
  DeleteSubTaskMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
  CopyMediaFolderMutation,
  CopyMediaMutation,
  CreateCardMutation,
  RenameMediaFolderMutation,
  TaskCommentsQuery,
  CreateTaskNoteMutation,
} from "@/gql";

import { extractMentionsFromContent } from "@/utils/StringHelpers";
import mixpanel from "@/mixpanel";
//import TaskDilalogDatePicker from "@/views/BoardView/TaskDilalogDatePicker";

import TaskFolderAttachments from "@/views/BoardView/TaskFolderAttachments";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";
import UpdateFileDialog from "@/components/Dialogs/UpdateFileDialog";
import CreateMediaFolderDialog from "@/components/Dialogs/CreateMediaFolderDialog";

export default {
  name: "SubTaskDialog",
  mixins: [UserScope, DocumentEditorMixin],
  props: [
    "tasks",
    "taskLabels",
    "backToTask",
    "workspace",
    "isWorkspaceOwner",
    "isPrivateTask",
    "currentUser",
    "authToken",
    "workspaceMembers",
    "subTask",
    "openSubtask",
    "openTaskComment",
    "currentCompany",
    "reactions",
    "deleteSubTaskHandler",
    "deleteTaskCommentHandler",
    "cardList",
    "subTaskId",
    "defaultCard",
    "detachTaskFolderAttachmentHandler",
    "activeCompany",
    "boardId",
    "isFromMessage",
    "hideTaskArrow",
  ],
  filters: {
    dateConversion(postGroupDate) {
      if (postGroupDate === format(new Date(), "MMM. do, yyyy")) {
        return "Today";
      } else if (
        postGroupDate === format(subDays(new Date(), 1), "MMM. do, yyyy")
      ) {
        return "Yesterday";
      }
      return postGroupDate;
    },
  },
  components: {
    // TaskDialogInviteTaskMember,
    AssignMemberToSubtask,
    Subtask,
    TaskComment,
    TaskAttachment,
    Editor,
    CommentEditor,
    SubtaskEditor,
    PdfViewDialog,
    ProfileDialog,
    VideoDialog,
    AudioDialog,
    ImageViewerDialog,
    ConfirmDialog,
    TaskAttachmentUploader,
    VueDropzoneFileUpload,
    EditCommentDialog,
    ReplyCommentDialog,
    //TaskDilalogDatePicker,
    TaskFolderAttachments,
    FolderDetailsDialog,
    PostCopyMoveFileFolder,
    UpdateFileDialog,
    CreateMediaFolderDialog,
    DocumentEditorDialog,
    // SubTaskDialog,
    TaskDocumentEditorDialog,
    Avatar,
  },
  api: {
    media: {
      query: NewMediaQuery,
      defaultValue: null,
      variables() {
        return {
          offset: 0,
          workspace_id: this.workspace.id,
        };
      },
      cacheKey() {
        return `MediaQuery:${this.workspace.id}`;
      },
    },
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    task: {
      query: TaskQuery,
      defaultValue: null,
      variables() {
        return {
          id: this.tasks.id,
        };
      },
      cacheKey() {
        return `TaskQuery:${this.tasks.id}`;
      },
    },
    subtask: {
      query: SubtaskQuery,
      defaultValue: null,
      variables() {
        return {
          id: this.subTask.id,
        };
      },
      cacheKey() {
        return `SubtaskQuery:${this.subTask.id}`;
      },
    },
    mediaFolders: {
      defaultValue: [],
      cacheKey() {
        return `MediaFoldersQuery:${this.workspace.id}`;
      },
    },
    taskComments: {
      query: TaskCommentsQuery,
      defaultValue: [],
      variables() {
        return {
          task_id: parseInt(this.tasks.id),
        };
      },
      cacheKey() {
        return `TaskCommentsQuery:${parseInt(this.tasks.id)}`;
      },
    },
  },
  beforeCreate() {
    this.$eventBus.$off("fetchMoreMedia");
  },
  created() {
    this.subtaskLoader = true;
    if (this.subTask) {
      const descFlag = this.$store.getters.showDescription(this.subTask.id);
      if (!descFlag) {
        // this.$store.dispatch("setSubtaskDescriptionState", {
        //   taskId: this.subTask.id,
        //   flag: true,
        // });
      }
      const attachmentFlag = this.$store.getters.showSubtaskAttachment(
        this.subTask.id
      );
      if (!attachmentFlag) {
        this.$store.dispatch("setSubtaskAttachmentState", {
          subtaskId: this.subTask.id,
          flag: true,
        });
      }
      this.taskData = { ...this.subTask };
      this.taskData.mentionedInDescription = extractMentionsFromContent(
        this.taskData.details,
        this.taggUsers
      );
      this.$eventBus.$on("closeTaskDialog", this.closeTaskDialog);
      this.$eventBus.$on("fetchMoreMedia", this.fetchMoreMedia);
    }
  },
  beforeDestroy() {
    document.body.classList.add("vabotu-body");
    this.$eventBus.$off("closeTaskDialog", this.closeTaskDialog);
    this.$eventBus.$off("fetchMoreMedia");
  },
  updated() {
    if (!this.isScrollbottom && document.querySelector("#commentScroll")) {
      this.isScrollbottom = true;
      document.querySelector("#commentScroll").scrollTop =
        document.querySelector("#commentScroll").scrollHeight;
    }
  },
  mounted() {
    this.mediaExpansion = this.showSubtaskAttachment;
    this.sound = new Audio(soundFile);
    this.isInvalid =
      !this.taskData.title || this.taskData.title.trim() === "" ? true : false;

    document.body.classList.remove("vabotu-body");
    this.expandAllPanel();

    if (this.subTasks && this.subTasks.length > 10) {
      setTimeout(() => {
        this.showSubtasks = true;
        this.subtaskLoader = false;
        this.visibleSpecificTaskDialogPart();
      }, 500);
    } else {
      this.subtaskLoader = false;
      this.showSubtasks = true;
      this.visibleSpecificTaskDialogPart();
    }
  },
  data() {
    return {
      taskNotes: null,
      date: this.subTask.end_date
        ? this.subTask.end_date
        : new Date().toISOString().split("T")[0],
      datePickermenu: false,
      isHover: false,
      currentSubTask: null,
      subTaskDialog: false,
      renameFileDialog: {
        flag: false,
      },
      renameFileDetails: null,
      addTaskLabel: false,
      panel: [],
      sound: null,
      labelmenu: false,
      loadAllComment: false,
      showSubtasks: false,
      subtaskLoader: false,
      commentLoader: false,
      editorFocused: false,
      editorTitleFocused: false,
      taskData: {
        id: null,
        completed: null,
        members: null,
        start_date: null,
        end_date: null,
        labels: [],
        title: "",
        details: "",
        subtasks: [],
      },
      newSubtask: null,
      newSubtaskEditorVisibility: false,
      rsbMemberHover: null,
      confirmRemoveMember: {
        flag: false,
        id: null,
      },
      visibility: "active",
      clearAll: false,
      toggle_multiple: [0, 1],
      editCommentContent: null,
      editSubtaskContent: null,
      taggedUserIds: [],
      mediaExpansion: false,
      pdfMediaData: null,
      showpdfModel: false,
      draggedContext: null,
      profileDialog: {
        show: false,
        user: null,
      },
      delete_media: {
        id: null,
        post: null,
        flag: null,
        type: null,
      },
      repliedComment: null,
      showVideoModel: false,
      showAudioModel: false,
      audioMediaData: null,
      displayPlayer: false,
      videoMediaData: null,
      dialogMedia: null,
      gridMediaId: "",
      showModal: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
      ],
      creatTaskLoader: false,
      isInvalid: false,
      belowSubtaskSequence: null,
      stopTimerConfirmation: {
        flag: false,
        id: null,
      },
      startNewTimer: {
        flag: false,
        activeTaskId: null,
      },
      timelogs: {
        flag: false,
      },
      mediaId: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      editCommentDialog: {
        flag: false,
      },
      replyCommentDialog: {
        flag: false,
      },
      showTaskLabel: false,
      selectedSubtask: null,
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      copyMoveDialog: {
        data: [],
        isFolder: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
      trackTimeMenu: false,
      selectedFields: [],
      customMenu: false,
      newFieldMenu: false,
      editField: false,
      selectedForEdit: null,
      checkbox: true,
      newField: null,
      selected: "",
      menu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      startTime: "09:00",
      endTime: "17:00",
      dropdownData: ["Checkbox", "Date", "Dropdown", "Number", "Text"],
      dates: null,
      isSwatchOpen: false,
      typeMenu: false,
      inputOptionText: null,
      CFOption: [],
      selectedCard: null,
      confirmRemoveField: false,
      editOption: {
        id: null,
      },
      isScrollbottom: false,
      subtaskDeleteConfirmDailog: {
        flag: false,
        completed: false,
        title: null,
      },
      renameMediaFolderDialog: {
        flag: false,
        loading: false,
      },
      renameFolderDetails: null,
      isTruncated: false,
    };
  },
  methods: {
    closeTaskNoteEditor() {
      console.log("call this closeTaskNoteEditor");
    },
    async openTaskNotes() {
      if (this.tasks.note) {
        this.taskNotes = this.tasks.note;
      } else {
        const response = await this.$api.mutate({
          mutation: CreateTaskNoteMutation,
          variables: {
            note: "",
            workspace_id: this.workspace.id,
            task_id: this.tasks.id,
          },
        });
        if (response.data) {
          const taskEntity = this.$api.getEntity("task", this.tasks.id);
          taskEntity.note = response.data.createTaskNote;
          this.taskNotes = response.data.createTaskNote;
        }
      }
    },
    changeActiveTab(value) {
      this.$store.dispatch("setTaskSidebarActiveTab", {
        workspaceId: this.workspaceId,
        flag: value,
      });
    },
    async detachTaskAttachmentHandler(args) {
      this.delete_media.post = args.task_id;
      this.delete_media.id = args.id;
      this.delete_media.type = "subtask";
      this.removeMediaDialogHandler(true);
    },
    async attachTaskMediaHandler(args) {
      this.updateTask({
        id: args.task_id,
        media: args.mediaIds,
      });
    },
    updatedDate(date) {
      if (date && date.type && date.type === "input") {
        date.preventDefault();
        return false;
      }
      this.taskData.end_date = date;
    },
    closeSubTaskDialog() {
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    handlePaste() {
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    },
    openRenameFolderDialog(folder) {
      this.renameFolderDetails = folder;
      this.renameMediaFolderDialog.flag = true;
    },
    cancelFolder() {
      this.renameFolderDetails = null;
      this.renameMediaFolderDialog.flag = false;
      this.renameMediaFolderDialog.loading = false;
    },
    async renameFolderHandler(title) {
      this.renameMediaFolderDialog.loading = true;
      if (this.renameFolderDetails) {
        try {
          const variables = {
            id: this.renameFolderDetails.id,
            title,
          };
          await this.$api.mutate({
            mutation: RenameMediaFolderMutation,
            variables,
          });
          const query = this.$api.getQuery(
            `MediaFoldersQuery:${this.workspace.id}`
          );

          query.data.mediaFolders = query.data.mediaFolders.map((f) => {
            if (f.id !== this.renameFolderDetails.id) {
              f.title = title;
            }
            return f;
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
          this.renameMediaFolderDialog.loading = false;
          this.renameMediaFolderDialog.flag = false;
          this.renameFolderDetails = null;
        }
      }
    },
    openRenameFileDialog(media) {
      this.renameFileDetails = media;
      this.renameFileDialog.flag = true;
    },
    cancel() {
      this.renameFileDetails = null;
      this.renameFileDialog.flag = false;
      this.renameFileDialog.loading = false;
    },
    deleteAllSubtasks(cFlag) {
      let title;
      if (cFlag) {
        title = "Are you sure you want to delete all your completed subtasks?";
      } else {
        title = "Are you sure you want to delete all your active subtasks?";
      }

      this.subtaskDeleteConfirmDailog.flag = true;
      this.subtaskDeleteConfirmDailog.title = title;
      this.subtaskDeleteConfirmDailog.completed = cFlag;
    },
    closeDialog() {
      this.saveTask();
    },
    updateOptionLabel() {
      this.editOption.id = null;
    },
    confirmRemoveFieldHandler(flag = true) {
      this.confirmRemoveField = flag;
    },
    confirmRemoveSubtaskHandler() {
      this.subtaskDeleteConfirmDailog.flag = false;
      this.subtaskDeleteConfirmDailog.title = null;
      this.subtaskDeleteConfirmDailog.completed = false;
    },
    handleDeleteSubtasks() {
      this.emitClearAll(
        this.subTask.id,
        this.subtaskDeleteConfirmDailog.completed
      );
      this.subtaskDeleteConfirmDailog.flag = false;
      this.subtaskDeleteConfirmDailog.title = null;
      this.subtaskDeleteConfirmDailog.completed = false;
    },
    async createCard(card) {
      const response = await this.$api.mutate({
        mutation: CreateCardMutation,
        variables: card,
      });

      this.cardList.push({
        board_id: response.data.createCard.board_id,
        id: response.data.createCard.id,
        sequence: response.data.createCard.sequence,
        title: response.data.createCard.title,
        user_id: response.data.createCard.user_id,
        __typename: response.data.createCard.__typename,
      });
      this.selectedCard = this.cardList[0];
    },
    copyFileFolderHandler(workspace, folder) {
      if (this.copyMoveDialog.isFolder) {
        this.copyFolders(workspace);
      } else {
        this.copyFiles(workspace, folder);
      }
    },
    async copyFolders(workspace) {
      try {
        this.copyMoveDialog.loading = true;
        // Array of folders
        const folderIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        await this.$api.mutate({
          mutation: CopyMediaFolderMutation,
          variables,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async copyFiles(workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: CopyMediaMutation,
          variables,
        });
        if (response.data) {
          //increase folder count when copy to folder
          if (workspace.id === this.currentWorkspace) {
            //cache update
            const query = this.$api.getQuery(
              `MediaFoldersQuery:${this.currentWorkspace}`
            );
            query.data.mediaFolders = query.data.mediaFolders.map((f) => {
              if (f.id === folder.id) {
                f.media_count += fileIds.length;
              }
              return f;
            });
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    copyMoveFileFolderHandler(items, isFolder = false, isRoot = true) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
    },
    closeCopyMoveFileFolderDialog() {
      this.copyMoveDialog.data = [];
      this.copyMoveDialog.flag = false;
      this.copyMoveDialog.loading = false;
      this.copyMoveDialog.isFolder = false;
      this.copyMoveDialog.post = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    closePlanExpiredDialog() {
      this.showTaskLabel = false;
    },
    openAddTaskLabel() {
      if (
        this.currentCompany.status &&
        this.currentCompany.status.subscriptionOver
      ) {
        this.showTaskLabel = true;
      } else {
        this.addTaskLabel = true;
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    async fileUploadCloseHandler(fileList) {
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      if (this.mediaId) {
        const media = this.$api.getEntity("media", this.mediaId);
        media.file = fileList[this.mediaId].file;
        media.thumb = fileList[this.mediaId].thumb;
      }
    },
    fromComputer(media_id) {
      this.mediaId = media_id;
      this.$refs.vueFileUploader.openFileDialog();
    },
    onAttachmentDragStart() {
      document.body.classList.add("dragging");
    },
    onAttachmentDragEnd() {
      document.body.classList.remove("dragging");
    },
    onAttachmentDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = this.taskAttachments ? [...this.taskAttachments] : [];
      if (event.from === event.to) tmp.splice(oldIndex, 1);
      tmp.splice(newIndex, 0, media);

      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );

      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
      mixpanel.track("Task Move");
    },
    updateCoverImage(mediaId) {
      const task = this.$api.getEntity("task", this.tasks.id);
      task.cover_image = mediaId;
      this.updateTask({
        id: this.tasks.id,
        cover_image: mediaId,
      });
      this.$q.notify({
        classes: "success-notification",
        position: "top-right",
        message: this.$t("Task cover set"),
        timeout: 2500,
        icon: this.$icons.matAnnouncement,
        actions: [{ icon: this.$icons.matClose, color: "white" }],
      });
    },
    addSubtaskBelow(subtask) {
      this.newSubtaskEditorVisibility = true;
      const currentIndex = this.subTasks.findIndex((a) => a.id === subtask.id);
      this.$refs.subtaskEditor.$refs.subtaskVisible.$refs.editor.fireFocus();
      // if (currentIndex === 0) {
      //   this.belowSubtaskSequence = this.subtaskSequenceGenerator(2, 1);
      // } else {
      this.belowSubtaskSequence = this.subtaskSequenceGenerator(
        currentIndex - 1,
        currentIndex
      );
      this.selectedSubtask = subtask;
      // }
    },
    addSubtaskAbove() {
      this.newSubtaskEditorVisibilityTop = !this.newSubtaskEditorVisibilityTop;
      this.newSubtaskEditorVisibility = false;
    },
    setTaskDescriptionState() {
      this.$store.dispatch("setTaskDescriptionState", {
        taskId: this.tasks.id,
        flag: !this.showTaskDesc,
      });
    },
    setSubtaskAttachmentState() {
      this.$store.dispatch("setSubtaskAttachmentState", {
        subtaskId: this.subTask.id,
        flag: !this.showSubtaskAttachment,
      });
    },
    chooseCard(card) {
      this.selectedCard = card;
    },
    //Update Message with title and card
    saveTask() {
      let updateTaskData = {
        id: this.tasks.id,
        title: this.taskData.title,
      };
      if (this.taskData.title !== this.tasks.title) {
        this.updateTask(updateTaskData);
      }
      // this.$refs.subtaskCommentEditor.submit();
      this.$refs.subtaskEditor.addSubTaskHandler();
      // this.$emit("updateTimeline");
      this.backToTask(this.tasks.id);
    },
    onDragStart() {
      document.body.classList.add("dragging");
    },
    onDragEnd() {
      document.body.classList.remove("dragging");
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const subTask = this.$api.getEntity("subTask", item.dataset.id);

      const tmp = [...this.subTasks];

      // move item from oldIndex to newIndex
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);

      subTask.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );

      this.$api.mutate({
        mutation: UpdateSubtaskMutation,
        skipUpdates: true,
        variables: {
          id: subTask.id,
          sequence: subTask.sequence,
          completed: subTask.completed,
        },
      });
      mixpanel.track("Task Subtask Move");
    },
    async changeCompleteStatus() {
      this.sound.currentTime = 0;
      this.sound.play();
      const variables = {
        id: this.tasks.id,
        completed: true,
      };
      this.$emit("close");
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
        mixpanel.track("Task Update");
        this.$mixpanelEvent("task-completed", {});
      } catch (error) {
        throw new Error(error);
      }
    },
    async fetchMoreMedia() {
      this.isloadingMedia = true;
      const lastMedia = last(this.media);
      if (lastMedia && lastMedia.id) {
        const variables = {
          workspace_id: this.workspace.id,
          cursor: lastMedia.id,
          cursor_type: "prev",
          limit: 10,
        };
        const currentMedia = this.$api.getQueryByName("media");
        const newMedia = await this.$api.query({
          query: NewMediaQuery,
          variables,
        });
        if (!isEmpty(newMedia.data.newMedia)) {
          currentMedia.data.newMedia = [
            ...currentMedia.data.newMedia,
            ...newMedia.data.newMedia,
          ];
        }
      }
      this.isloadingMedia = false;
    },
    expandAllPanel() {
      if (this.commentGroup && Object.keys(this.commentGroup).length) {
        this.panel = [
          ...Array(Object.keys(this.commentGroup).length).keys(),
        ].map(() => true);
      }
    },
    visibleSpecificTaskDialogPart() {
      if (this.openSubtask) {
        this.visibleSubtaskAdder();
      }
    },
    fetchCommentScrollHandler(index, done) {
      this.commentLoader = true;
      const lastMedia = last(this.taskComments);
      if (lastMedia) {
        const variables = {
          task_id: this.tasks.id,
          cursor: lastMedia.id,
          cursor_type: "prev",
          limit: 10,
        };

        const currentTaskComments = this.$api.getQueryByName("taskComments");
        this.$api
          .query({
            query: TaskCommentsQuery,
            variables,
          })
          .then((res) => {
            this.commentLoader = false;
            currentTaskComments.data.taskComments = [
              ...currentTaskComments.data.taskComments,
              ...res.data.taskComments,
            ];
            done(res.data.taskComments.length === 0);
          });
      }
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        find(
          this.$refs.mediaCommentingView.$refs.commentBox,
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    videoPlayer(media) {
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    replyTocomment(data) {
      this.replyCommentDialog.flag = true;
      this.repliedComment = data;
    },
    removeMedia(postId, mediaId, type) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
      this.delete_media.type = type;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        if (this.delete_media.type === "subtask") {
          const variables = {
            subtask_id: this.delete_media.post,
            media: [this.delete_media.id],
          };
          await this.$api.mutate({
            mutation: DetachSubtaskMediaMutation,
            variables,
          });
        } else {
          const variables = {
            task_comment_id: this.delete_media.post,
            media: [this.delete_media.id],
          };

          await this.$api.mutate({
            mutation: DetachTaskCommentMediaMutation,
            variables,
          });
        }
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    handleEditorFocus() {
      this.editorFocused = true;
    },
    handleTitleEditorFocus() {
      this.editorTitleFocused = true;
    },
    showUserInfo(user) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
    },
    visibleSubtaskAdder() {
      this.newSubtaskEditorVisibility = true;
      // if (!this.newSubtaskEditorVisibilityTop) {
      //   if (!this.editSubtaskContent) {
      //   }
      // }
    },
    closeTaskDialog() {
      const query = this.$api.getQuery("NotificationsQuery");
      let notification_ids;
      if (query.data) {
        notification_ids = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              (n.action.type === "task-comment-created" &&
                n.action.object &&
                n.action.object.task_id === this.tasks.id) ||
              (n.action.type === "subtask-member-attached" &&
                n.action.object &&
                n.action.object.task_id === this.tasks.id) ||
              (n.action.type === "task-member-attached" &&
                n.action.object_id === this.tasks.id) ||
              (n.action.type === "task-comment-reaction-added" &&
                n.action.object &&
                n.action.object.task_id === this.tasks.id)
          )
          .map((n) => {
            n.read = true;
            return n.id;
          });
      }
      this.readTaskNotifications(notification_ids);
    },
    async readTaskNotifications(notification_ids) {
      if (notification_ids.length) {
        const variables = {
          notification_ids: notification_ids,
          read: true,
        };
        await this.$api.mutate({
          mutation: UpdateNotificationsMutation,
          variables,
        });
      }
    },
    updateTaskDescription() {
      this.updateTask({
        id: this.subTask.id,
        details: this.taskData.details !== "" ? this.taskData.details : null,
      });
      this.editorFocused = false;
    },
    updateSubTaskTitle() {
      if (!this.taskData.title || this.taskData.title.trim() === "") {
        this.isInvalid = true;
        return;
      }
      this.updateTask({
        id: this.subTask.id,
        title: this.taskData.title !== "" ? this.taskData.title : null,
      });
      this.isInvalid = false;
      // this.editorTitleFocused = false;
    },
    reset() {
      this.taskData = {
        id: null,
        members: [],
        start_date: null,
        end_date: null,
        labels: [],
        title: null,
        details: null,
        subtasks: [],
      };
    },
    dateUpdateHandler(dates = null) {
      //if(dates) {
      this.taskData.end_date = dates;
      this.updateTask({
        id: this.subTask.id,
        end_date: dates,
      });
      //}
      this.datePickermenu = false;
    },

    addSubtask(obj) {
      if (this.editSubtaskContent) {
        this.updateSubtask({
          id: this.editSubtaskContent.id,
          title: obj.newSubtask,
          media: obj.uploadedFileIds,
          completed: this.editSubtaskContent.completed,
          mediafolder: obj.mediafolder,
          folder: obj.folder, // Folder ids
        });
        this.resetSubtaskEditor();
      } else {
        let sequence;
        if (this.belowSubtaskSequence) {
          sequence = this.belowSubtaskSequence;
          let subtaskElement = document.getElementById(
            "subtask-" + this.selectedSubtask.id
          );
          if (subtaskElement) {
            subtaskElement.scrollIntoView();
          }
          this.selectedSubtask = null;
        } else {
          const subTasks = this.subTasks;
          sequence =
            subTasks && subTasks.length
              ? RandomPosition.between(
                  last(subTasks).sequence,
                  RandomPosition.last()
                )
              : RandomPosition.between(
                  RandomPosition.first(),
                  RandomPosition.last()
                );
          this.$nextTick(() => {
            if (document.getElementById("scrollableTaskDialog")) {
              document.getElementById("scrollableTaskDialog").scrollTop =
                document.getElementById("scrollableTaskDialog").scrollHeight -
                document.getElementById("scrollableTaskDialog").clientHeight;
            }
          });
        }
        const newSubtask = {
          id: Math.round(Math.random() * -1000000),
          title: obj.newSubtask,
          completed: false,
          task_id: this.tasks.id,
          sequence,
          uploadedFileIds: obj.uploadedFileIds,
          optmedia: obj.optmedia,
          workspace_id: obj.workspace_id,
          media_urls: obj.media_urls,
          mediafolder: obj.mediafolder,
          folder: obj.folder, // Folder ids
          parent_id: this.subTask.id,
        };
        this.$emit("createSubTask", newSubtask);
        this.newSubtask = null;
        this.belowSubtaskSequence = null;
        this.visibleSubtaskAdder();
      }
      this.$eventBus.$emit("resetCreateSubtaskEditable");
    },
    async deleteSubTaskMember() {
      const variables = {
        subtask_id: this.subTask.id,
        user_id: this.confirmRemoveMember.id,
      };

      const subtaskEntity = this.$api.getEntity("subTask", this.subTask.id);

      subtaskEntity.members = subtaskEntity.members.filter(
        (m) => m.id !== this.confirmRemoveMember.id
      );

      this.confirmRemoveMember.flag = false;

      await this.$api.mutate({
        mutation: DeleteSubTaskMemberMutation,
        variables,
      });
      mixpanel.track("Task Member Remove");
    },
    async toggleTaskLabel(label) {
      this.labelmenu = false;
      const variables = {
        task_id: this.tasks.id,
        label_id: label.id,
      };

      const newLabels = this.tasks.labels.filter(
        (item) => item.id !== label.id
      );

      if (this.selectedLabels.includes(label.id)) {
        const task = this.$api.getEntity("task", this.tasks.id);
        this.tasks.labels = newLabels;
        task.labels = newLabels;

        await this.$api.mutate({
          mutation: DetachTaskLabelMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Label Remove");
      } else {
        if (this.tasks.labels.length < 4) {
          if (newLabels.length === this.tasks.labels.length) {
            newLabels.push(label);
          }
          const task = this.$api.getEntity("task", this.tasks.id);
          this.tasks.labels = newLabels;
          task.labels = newLabels;
          await this.$api.mutate({
            mutation: AttachTaskLabelMutation,
            variables,
            skipUpdates: true,
          });
          mixpanel.track("Task Label Add");
        } else {
          this.$q.notify({
            position: "top-right",
            message: "You can set up to 4 labels",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            classes: "max-label-notification",
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
    },
    confirmBoxHandler(flag = true, id = null) {
      this.confirmRemoveMember = {
        flag,
        id,
      };
    },
    async updateTask(args) {
      const variables = {
        ...args,
        completed: false,
      };
      const subtask = this.$api.getEntity("subTask", variables.id);
      this.$api.updateEntity("subTask", variables.id, {
        ...subtask,
        ...variables,
      });

      await this.$api.mutate({
        mutation: UpdateSubtaskMutation,
        variables,
      });
      mixpanel.track("Sub Task Update");
    },
    active(todos) {
      if (todos) {
        return todos.filter((todo) => !todo.completed);
      } else {
        return [];
      }
    },
    completed(todos) {
      if (todos) {
        return todos.filter((todo) => todo.completed);
      } else {
        return [];
      }
    },
    async deleteSubtask(args) {
      const variables = {
        ...args,
      };
      const subtaskEntity = this.$api.getEntity("subTask", this.subTask.id);
      subtaskEntity.childSubtasks = subtaskEntity.childSubtasks.filter(
        (s) => s.id !== args.id
      );
      if (this.subTasks.length === 0 && this.visibility === "completed") {
        this.visibility = "active";
      }
      await this.$api.mutate({
        mutation: DeleteSubTaskMutation,
        variables,
      });
      mixpanel.track("Task Subtask Delete");
    },
    async changeSubtaskCompleteStatus(subtask) {
      this.sound.currentTime = 0;
      this.sound.play();
      const variables = {
        id: subtask.id,
        completed: subtask.completed,
      };
      if (this.subTasks.length === 0 && this.visibility === "completed") {
        this.visibility = "active";
      }
      await this.$api.mutate({
        mutation: UpdateSubtaskMutation,
        variables,
      });
      mixpanel.track("Task Subtask Update");
    },
    async openSubtaskDialog(subtask) {
      this.subTaskDialog = true;
      this.currentSubTask = subtask;
    },
    async updateSubtask(args) {
      const variables = {
        ...args,
      };

      const subtask = this.$api.getEntity("subTask", args.id);
      const updatesSubtask = {
        ...args,
        media: subtask.media,
      };
      if (args.media && args.media.length) {
        const newAddedMedia = args.media.map((m) => {
          const media = this.$api.getEntity("media", m);
          return media;
        });
        updatesSubtask.media = [...newAddedMedia, ...subtask.media];
      }
      this.$api.updateEntity("subTask", variables.id, updatesSubtask);

      await this.$api.mutate({
        mutation: UpdateSubtaskMutation,
        variables,
      });
      mixpanel.track("Task Subtask Update");
    },
    cancelClearAllSubtasks() {
      this.clearAll = false;
      this.toggle_multiple = [0, 1];
    },
    emitClearAll(id, completed) {
      this.clearAll = false;
      this.toggle_multiple = [0, 1];
      this.$emit("clearSubtasks", { id, completed, childSubtask: true });
      this.visibility = "active";
    },
    scrollToBottom() {
      this.$nextTick(() => {
        document.querySelector("#commentScroll").scrollTop =
          document.querySelector("#commentScroll").scrollHeight;
      });
    },
    resetAddCommentEditor() {
      this.editCommentContent = null;
      this.editCommentDialog.flag = false;
    },
    async updateTaskComment(args) {
      const variables = {
        id: args.id,
        workspace_id: args.workspace_id,
        comment: args.comment,
        media: args.media,
        media_urls: args.media_urls,
        tagged_user_ids: args.tagged_user_ids,
        folder: args.folder,
      };
      const comment = this.$api.getEntity("taskComment", args.id);
      if (args.media.length) {
        const newAddedMedia = args.media.map((m) => {
          const media = this.$api.getEntity("media", m);
          return media;
        });
        comment.media = [...newAddedMedia, ...comment.media];
      }
      if (args.mediafolder && args.mediafolder.length) {
        comment.mediafolder = [...args.mediafolder];
      }
      comment.comment = args.comment;
      comment.tagged_user_ids = args.tagged_user_ids;
      const updatedComment = {
        ...comment,
        sharedTaskComment: null,
        commentreactiondata: [],
        commentreactionset: [],
      };
      this.$api.updateEntity("taskComment", variables.id, updatedComment);
      this.resetAddCommentEditor();
      mixpanel.track("Task Comment Update");
      await this.$api.mutate({
        mutation: UpdateTaskCommentMutation,
        variables,
      });
    },
    fullview(media) {
      this.dialogMedia = media;
      this.showModal = true;
      this.gridMediaId = media.id;
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
      this.$emit("closeMediaModel", {
        id: this.gridMediaId,
      });
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    updateSubtaskModHandler(args) {
      this.newSubtaskEditorVisibility = true;
      this.editSubtaskContent = args;
    },
    resetSubtaskEditor() {
      this.$refs.subtaskEditor.$refs.subtaskVisible.$refs.editor.resetTextArea();
      this.newSubtaskEditorVisibility = false;
      this.editSubtaskContent = null;
    },
    updateCommentModHandler(args) {
      this.editCommentContent = args;
      this.editCommentDialog.flag = true;
    },
    async createComment(comment, id = null) {
      const variables = {
        comment: comment.text,
        tagged_user_ids: comment.tagged_user_ids,
        is_active: true,
        task_id: this.tasks.id,
        media: comment.media,
        optmedia: comment.optmedia,
        workspace_id: comment.workspace_id,
        media_urls: comment.media_urls,
        task_comment_id: id,
        fullComment: this.repliedComment,
        mediafolder: comment.mediafolder,
        folder: comment.folder,
      };
      this.$emit("createComment", variables);

      //Remove task comment notification on creation of new comment
      const query = this.$api.getQuery("NotificationsQuery");
      let notification_ids;
      if (query.data) {
        notification_ids = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              n.action.type === "task-comment-created" &&
              n.action.object.task_id === this.tasks.id
          )
          .map((n) => {
            n.read = true;
            return n.id;
          });
      }
      this.readTaskNotifications(notification_ids);
      this.scrollToBottom();
    },
    //Create comment
    commentEditorActionsHandler(comment) {
      this.commentLoader = false;
      this.createComment(comment);
      mixpanel.track("Task Comment Create");
    },
    // Edit Comment Handler
    editCommentHandler(comment) {
      this.updateTaskComment({
        id: this.editCommentContent.id,
        comment: comment.text,
        tagged_user_ids: comment.tagged_user_ids,
        task_id: this.editCommentContent.task_id,
        created_at: this.editCommentContent.created_at,
        updated_at: this.editCommentContent.updated_at,
        media: comment.media,
        workspace_id: comment.workspace_id,
        media_urls: comment.media_urls,
        folder: comment.folder,
        mediafolder: comment.mediafolder,
      });
    },
    // Reply comment Hadler
    replyCommentHandler(comment) {
      this.createComment(comment, this.repliedComment.id);

      this.replyCommentDialog.flag = false;
      this.repliedComment = null;
      mixpanel.track("Task Comment Create", { quote: true });
    },
    onClearAllSubtask() {
      this.toggle_multiple = [0, 1];
      this.clearAll = true;
    },
    pdfView(media) {
      // this.pdfMediaData = media;
      // this.showpdfModel = true;
      // if (this.fileExt.match(/pdf.*/)) {
      //   window.open(media.file, "_self");
      // } else {
      // }
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(media.file);
      else window.open(media.file, "_blank");
    },
    resetPdfDialog() {
      setTimeout(() => {
        this.showpdfModel = false;
      }, 10);
    },
    checkMove(event) {
      this.draggedContext = event.draggedContext;
    },
    cardDraggedEnd() {
      this.draggedContext = null;
    },
    subtaskSequenceGenerator(index, futureIndex, top = false) {
      let newIndex;
      if (top) {
        newIndex =
          this.subTasks.length > 0
            ? RandomPosition.between(
                RandomPosition.first(),
                head(this.subTasks).sequence
              )
            : RandomPosition.between(
                RandomPosition.first(),
                RandomPosition.last()
              );
        return newIndex;
      } else if (futureIndex === 0) {
        if (this.subTasks.length === 1) {
          newIndex = RandomPosition.between(
            this.subTasks[0].sequence,
            RandomPosition.last()
          );
          return newIndex;
        }
        newIndex = RandomPosition.between(
          this.subTasks[0].sequence,
          this.subTasks[1].sequence
        );
        return newIndex;
      } else if (futureIndex === findLastIndex(this.subTasks)) {
        newIndex = RandomPosition.between(
          last(this.subTasks).sequence,
          RandomPosition.last()
        );
        return newIndex;
      } else if (
        futureIndex < index &&
        futureIndex !== 0 &&
        futureIndex !== findLastIndex(this.subTasks)
      ) {
        let ref = futureIndex - 1;
        newIndex = RandomPosition.between(
          this.subTasks[ref].sequence,
          this.subTasks[futureIndex].sequence
        );
        return newIndex;
      } else if (
        futureIndex > index &&
        futureIndex !== 0 &&
        futureIndex !== findLastIndex(this.subTasks)
      ) {
        let ref = futureIndex + 1;
        newIndex = RandomPosition.between(
          this.subTasks[futureIndex].sequence,
          this.subTasks[ref].sequence
        );
        return newIndex;
      }
    },
    async cancelTask() {
      // this.$emit("updateTimeline");
      this.$emit("close", this.isFromMessage);
    },
  },
  computed: {
    activeTab() {
      const activetab = this.$store.getters.activeTaskTab(this.workspaceId)
        ? this.$store.getters.activeTaskTab(this.workspaceId)
            .taskSidebarActiveTab
        : "comments";

      if (activetab === "notes") {
        this.openTaskNotes();
      }
      return activetab;
    },
    copySubTaskLink() {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.activeCompany}/workspaces/${this.workspace.id}/board?task=${this.task.id}&workspace=${this.workspace.id}&sub_task=${this.subTask.id}&workspaceName=${this.workspace.title}`;
      return taskLink;
    },
    isEndDateDue() {
      if (
        format(new Date(this.taskData.end_date), "yyyy-MM-dd") ===
        format(new Date(), "yyyy-MM-dd")
      ) {
        return "due-date-today";
      } else if (isPast(parseISO(this.taskData.end_date))) {
        return "overdue-date";
      }
      return "due-date";
    },
    folderList() {
      if (this.mediaFolders) {
        return uniqBy(this.mediaFolders, "id");
      } else {
        return [];
      }
    },
    cardTask() {
      return this.tasks ? { ...this.tasks } : {};
    },
    showTaskDesc() {
      return this.$store.getters.showDescription(this.tasks.id).showDescription;
    },
    showSubtaskAttachment() {
      return this.subTask
        ? this.$store.getters.showSubtaskAttachment(this.subTask.id)
            .showSubtaskAttachment
        : false;
    },
    fileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    imageList() {
      if (this.media) {
        return uniqBy(orderBy(this.media, ["id"], ["desc"]), "id");
      }
      return null;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.taskAttachments.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.taskAttachments.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    descriptionData() {
      return compile(this.taskData.details);
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
    todaytask() {
      return format(new Date(), "MMM. do, yyyy");
    },
    yesterdayTask() {
      return format(subDays(new Date(), 1), "MMM. do, yyyy");
    },
    totalCommentsCount() {
      return this.orderedTaskComments.length;
    },
    commentGroup() {
      if (this.orderedTaskComments) {
        return groupBy(this.orderedTaskComments, (comment) => {
          return format(fromUnixTime(comment["created_at"]), "MMM. do, yyyy");
        });
      } else {
        return null;
      }
    },
    totalTaskLength() {
      return this.completedCount + this.activeCount;
    },
    selectedLabels: {
      get() {
        return this.tasks.labels.map((l) => l.id);
      },
    },
    taggUsers() {
      if (this.subtask && this.subtask.owner) {
        let taskMember =
          this.workspaceMemberList !== null
            ? [...this.workspaceMemberList]
            : null;
        const taskOwner = { ...this.subtask.owner };
        taskOwner.fullname = `${taskOwner.first} ${taskOwner.last}`;
        if (taskMember) {
          taskMember.push(taskOwner);
        } else {
          taskMember = [taskOwner];
        }
        return orderBy(uniqBy(taskMember, "id"), ["first", "last"], ["asc"]);
      } else {
        return this.taskMembers;
      }
    },
    completedCount() {
      return this.completed(this.subTask && this.subTask.childSubtasks).length;
    },
    activeCount() {
      return this.active(this.subTask && this.subTask.childSubtasks).length;
    },
    completedSubtaskProgress() {
      const progress =
        this.subTask && this.subTask.childSubtasks.length > 0
          ? (this.completedCount / this.subTask.childSubtasks.length) * 100
          : 0;
      return Math.round(progress);
    },
    subTasks: {
      get() {
        const subTaskGroup = groupBy(
          this.subTask && this.subTask.childSubtasks,
          "completed"
        );

        const key = this.visibility === "active" ? false : true;

        return orderBy(uniqBy(subTaskGroup[key], "id"), ["sequence"]);
      },
    },
    orderedTaskComments: {
      get() {
        return orderBy(
          uniqBy(this.taskComments, "id"),
          ["created_at"],
          ["asc"]
        );
      },
    },
    taskAttachments: {
      get() {
        return orderBy(uniqBy(this.subTask && this.subTask.media, "id"), [
          "sequence",
        ]);
      },
    },
    taskFolders: {
      get() {
        return orderBy(uniqBy(this.subTask && this.subTask.mediafolder, "id"), [
          "id",
        ]);
      },
    },
    canRemoveAttachment() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    taskMembers() {
      return this.subTask.members;
    },
    workspaceMemberList() {
      return this.workspaceMembers;
    },
    hasComments() {
      return this.taskComments && this.taskComments.length;
    },
    first2TaskMembers() {
      return this.taskMembers !== undefined && this.taskMembers.length > 2
        ? this.taskMembers.slice(0, 2)
        : this.taskMembers;
    },
    copyTaskLink() {
      const taskLink = `${process.env.VUE_APP_APPBASE_URL}/companies/${this.currentCompany.companyUri}/workspaces/${this.workspace.id}/board?task=${this.tasks.id}&workspace=${this.workspace.id}&workspaceName=${this.workspace.title}`;
      return taskLink;
    },
  },
  watch: {
    menu(val) {
      if (!val) {
        this.isHover = false;
      }
    },
    task(value) {
      if (value) {
        setTimeout(() => {
          this.expandAllPanel();
        }, 50);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// task-meber-box-h = 320px;
// add-member-menu-btn = 35px;

// .sel-task-member {
//   height: 'calc(%s - 74px)' % task-meber-box-h;
//   overflow-y: auto;
// }

.user-pic {
  height: 50px !important;
  width: 50px !important;
}

.editor-prototype p {
  margin: 0 !important;
}

.list {
  .item {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    cursor: pointer;
    color: #000000;
    // width: 160px;
    .user_pic {
      border: solid 1px #e3e8ed;
      background-color: #e3e8ed;
      span {
        color: #42526e;
        font-size: 10px;
        font-weight: 600;
      }
    }
    .close {
      opacity: 0;
    }
  }
  .item:hover {
    .close {
      opacity: 1;
    }
  }
}
.task-description-new-editor {
  font-weight: 500;
  font-style: normal;
  font-size: 15px !important;
  font-family: "Avenir Next";
  .editor {
    padding: 0px;
  }
}
.task-description {
  padding-right: 10px !important;
  p {
    margin-top: 0px !important;
  }
}
.copy-task-link:hover {
  color: #007aff !important;
}
.custom-header {
  display: flex;
  width: 200px;
}
.space {
  margin-left: 6%;
  margin-right: 1%;
}
.close-button {
  margin-top: 1px;
  margin-left: 45px;
  font-size: 20px;
}
.new-close-button {
  margin-top: 1px;
  margin-left: 65px;
  font-size: 20px;
}
.input-styles {
  height: 30px;
  width: 140px;
  background-color: #f4f5f8;
  display: flex;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
}

.image {
  padding-left: 17px;
  padding-right: 17px;
  height: 13px;
  width: 20px;
  display: flex;
  justify-content: center;
}
.custom-add-task {
  color: #007aff !important;
  background-color: #dbecff;
  border-radius: 6px;
  height: 30px;
  margin-top: 3px;
  width: 30px;
}
.custom-add-task:hover {
  border: 1px solid #007aff;
}
.text-grey {
  color: #919ca8;
}
.add-new {
  height: 30px;
  width: 175px;
  background-color: #dbecff;
  color: #007aff !important;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 6px;
  text-align: center;
  padding-top: 4px;
}
.field-input {
  height: 20px !important;
  border-radius: 5px;
  border: solid 1px #b8c1cb;
  background-color: #f4f5f8;
  padding-bottom: 29px;
  padding-left: 8px;
}
.dropdown {
  .q-btn__wrapper:before {
    border: 1px solid #c0cfe0;
  }
}
.tag-title {
  padding-bottom: 5px;
}
.create-field-btn {
  color: white !important;
  width: 96%;
  height: 30px;
}
.icon-hover:hover {
  .img-pos {
    color: #ffffff;
  }
}
.img-pos {
  color: #007aff;
  position: absolute;
  left: 10px;
}
.img-pos.q-icon {
  font-size: 16px !important;
}
.add-new-field-wrapper {
  position: absolute;
  bottom: 0;
  background: white;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
.custom-field-list {
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 36px;
}
.new-field {
  margin-bottom: 10px;
  margin-right: 0px !important;
  span {
    .q-btn .q-icon .q-btn .q-spinner {
      font-size: 10px !important;
    }
  }
}
.task-ticket-number {
  font-size: 15px;
  font-weight: 600;
  color: #a6b1bd;
  margin-left: 10px;
}
.copylink {
  &:hover {
    color: #007aff;
  }
}
</style>
