<template>
  <div
    class="card card--flat card--tile relative-position media-card-hover"
    :class="mediaPreview.hoverClass"
    style="border-radius: 10px"
    :style="{
      width: height && '90px',
      border: '1px solid #D7E0E7',
      'background-color': mediaPreview.bgColor,
    }"
  >
    <div
      class="task-menu-option row items-center thread-icons q-px-xs q-mt-sm q-mr-xs "
      style="z-index: 12"
    >
      <div
        v-if="media.isbookmarked"
        class="cursor-pointer"
        @click.stop="removeMediaBookmark(media)"
      >
        <q-icon size="20px" color="pink" :name="$icons.matBookmark">
          <q-tooltip v-model="bookmarkTooltip">
            Remove from Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <div
        v-if="!media.isbookmarked"
        @click.stop="addMediaBookmark(media)"
        class="cursor-pointer"
      >
        <q-icon
          size="20px"
          class=" cursor-pointer"
          color="black"
          :name="$icons.matBookmarkBorder"
        >
          <q-tooltip v-model="bookmarkTooltip">
            Add to Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <q-btn
        color="white"
        text-color="black"
        size="13px"
        round
        dense
        padding="0"
        style="z-index:100"
        class="thread-icon"
        :icon="$icons.matMoreHoriz"
        @click.stop
      >
        <q-menu
          content-style="min-width:150px; padding: 0 9px;z-index:9001"
          v-model="mediMenu"
          auto-close
        >
          <q-list class="q-py-sm">
            <q-item
              avatar
              @click.stop="downloadFile(mediaId)"
              v-if="isS3"
              clickable
            >
              <q-item-section>
                Download
              </q-item-section>
            </q-item>
            <q-item
              avatar
              @click.stop="$copyTextToClipboard(media.file)"
              clickable
            >
              <q-item-section>
                Copy Link
              </q-item-section>
            </q-item>
            <q-item @click="fromComputer" v-if="isImg && canRemove" clickable>
              <q-item-section>
                Replace image
              </q-item-section>
            </q-item>
            <q-item
              @click="copyMoveFileFolderHandler([media], false, true)"
              v-if="isImg && canRemove && !isSavedMessageMedia"
              clickable
            >
              <q-item-section>
                Copy
              </q-item-section>
            </q-item>
            <q-item
              @click="$emit('renameFile', media)"
              v-if="canRemove"
              clickable
            >
              <q-item-section>
                Rename
              </q-item-section>
            </q-item>
            <q-item
              @click="clickonmoreoption({ label: 'deletefile' })"
              v-if="canRemove"
              clickable
              class="text-red"
            >
              <q-item-section>
                Remove
              </q-item-section>
            </q-item>
            <q-item
              v-if="media.is_document_section && !media.is_read_only && (currentUser && currentUser.id === media.user_id)"
              @click="updateReadOnlyHandler(true)"
              clickable
              class="q-mb-sm"
              v-close-popup
            >
              <q-item-section>
                Set to read only
              </q-item-section>
            </q-item>
            <q-item
              v-else-if="media.is_document_section && media.is_read_only  && (currentUser && currentUser.id === media.user_id)"
              @click="updateReadOnlyHandler(false)"
              clickable
              class="q-mb-sm"
              v-close-popup
            >
              <q-item-section>
                Remove read only
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <q-img
      :height="mediaDiamension"
      :src="imgSrc"
      :style="{ width: mediaDiamension }"
      class="border-radius10"
    >
      <q-tooltip v-if="!bookmarkTooltip">
        {{ media.filename }}
      </q-tooltip>
      <div
        v-if="isVideo && !isDropbox"
        @click="videoPlayer(media)"
        class="row items-center justify-center q-pa-none fill-height full-width	"
        style="z-index:10"
      >
        <div class="text-center flex-1">
          <q-btn
            :icon="$icons.mdiPlay"
            color="primary"
            size="lg"
            round
            dense
            flat
          />
        </div>
      </div>
      <div
        @click="openViewMode"
        class="fill-height full-width media-card-hover-item cursor-pointer"
      ></div>
    </q-img>
    <div
      class="absolute media-comment-badge-messaging"
      v-if="
        media.type.match(/image.*/) &&
          media.commentPoint &&
          media.commentPoint.length > 0
      "
      :style="
        media.commentPoint.length == completedMediaPoints.length
          ? `background: #15d89a`
          : ''
      "
    >
      <span>{{
        completedMediaPoints.length + "/" + media.commentPoint.length
      }}</span>
    </div>
  </div>
</template>
<script>
import { getDownloadUrl } from "@/services/downloadS3File";
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { AddMediaBookmarkMutation, RemoveMediaBookmarkMutation, UpdateMediaMutation } from "@/gql";

export default {
  name: "CardMediaCollaboration",
  mixins: [FileExtensionMixin],
  props: [
    "media",
    "height",
    "removeMedia",
    "fullview",
    "canRemoveMedia",
    "postId",
    "videoPlayer",
    "audioPlayer",
    "pdfView",
    "fromComputer",
    "copyMoveFileFolderHandler",
    "editDoc",
    "isSavedMessageMedia",
    "currentUser"
  ],
  data() {
    return {
      mediMenu: false,
      bookmarkTooltip: false,
    };
  },
  methods: {
    async updateReadOnlyHandler(flag) {
      try {
        let is_read_only = flag;
        const variables = {
          id: this.media.id,
          is_read_only,
        };
        await this.$api.mutate({
          mutation: UpdateMediaMutation,
          skipUpdates: true,
          variables,
        });

        const media = this.$api.getEntity("media", this.media.id);
        media.is_read_only = is_read_only;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: flag ? "Document added read only mode." : "Document remove read only mode.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    openViewMode() {
      if (this.isImg) {
        this.fullview({ media: this.media, newpoint: "no" });
      } else if (this.isDoc && this.isS3) {
        this.openNextTabLink();
      } else if (this.isAudio) {
        this.audioPlayer(this.media);
      } else if (this.isGoogle || this.isDropbox) {
        this.openNextTabLink();
      } else if (this.isVideo && this.isS3) {
        this.videoPlayer(this.media);
      } else if (this.isZip) {
        this.downloadFile(this.media.id);
      } else if (this.media.is_document_section) {
        this.editDoc(this.media);
      }
    },
    openNextTabLink() {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(this.media.file);
      else window.open(this.media.file, "_blank");
    },
    pdfViewMethod(media) {
      this.pdfView(media);
    },
    clickonmoreoption(name) {
      let data;
      switch (name.label) {
        case "addcomment":
          data = {
            media: this.media,
            newpoint: "yes",
          };
          this.fullview(data);
          break;
        case "copylink":
          data = {
            title: "Copy Link",
            media: this.media,
            forscroll: "no",
          };
          this.$emit("copyLink", data);
          break;
        case "deletefile":
          this.removeMedia(this.postId, this.mediaId);
          break;
        default:
          break;
      }
    },
    async downloadFile(id) {
      this.mediMenu = false;
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
      return true;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    mediaId() {
      return this.media.id;
    },
    fileExt() {
      if (this.media) {
        return this.media.filename.split(".")[1];
      }
      return null;
    },
    isDoc() {
      if (
        this.fileExt &&
        (this.fileExt.match(/pdf.*/) ||
          this.fileExt.match(/txt.*/) ||
          this.fileExt.match(/html.*/) ||
          this.fileExt.match(/doc.*/) ||
          this.fileExt.match(/pptx.*/) ||
          this.fileExt.match(/xls.*/) ||
          this.fileExt.match(/rtf.*/))
      ) {
        return true;
      }
      return false;
    },
    isS3() {
      return this.media.file_resource === "s3";
    },
    isGoogle() {
      return this.media.file_resource === "google";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    filename() {
      return this.media.filename;
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      // Used Mixin : FileExtensionMixin
      if (this.isGoogle) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isImg) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      return this.mediaPreview.type;
    },
    canRemove() {
      return this.canRemoveMedia;
    },
    mediaDiamension() {
      if (this.height) return `${this.height}px`;
      return "106px";
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
  },
};
</script>
